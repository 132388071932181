import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { Footer, Header } from "../components";
import PageTitle from "../components/PageTitle";
import logo from "../images/logo.svg";
import pageTitleBg from "../images/page-title-bg.png";
import downloadIcon from "../images/icons/download.svg";
import "../styles/brand-assets.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Hat } from "../components/Header";

const BrandAssets = () => {
  React.useEffect(() => {
    AOS.init({
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <div className="brand-assets">
        <Hat title="Brand Assets" />
        <Header />
        <PageTitle title="Brand Assets" background={pageTitleBg} />
        <Container>
          <div className="content__wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12} lg={7}>
                <div className="logo-description" data-aos="fade-right">
                  <h1>Logo</h1>
                  <p className="margin-bottom-50">
                    This logo should appear on all Insured Finance
                    communications in one way or
                    <br /> another. Consistency and visibility is important.
                    This is the preferred version of <br /> the Insured Finance
                    logo. Other acceptable versions of the logo will be
                    <br /> presented later.
                  </p>
                  <Button
                    variant="contained"
                    className="default-button"
                    href="https://drive.google.com/u/0/uc?id=1uo7ngmhgj_wfQdh-t1S2EJ7ImVdzTOMw&export=download"
                    // target="_blank"
                    download
                    sx={{ display: { xs: "none", lg: "block" } }}
                  >
                    <img src={downloadIcon} className="margin-right-10" />
                    Download
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <div className="logo-picture" data-aos="fade-left">
                  <img src={logo} />
                </div>

                <div
                  className="logo-description"
                  data-aos="fade-right"
                  style={{ marginTop: "20px" }}
                >
                  <Button
                    variant="contained"
                    className="default-button mobile"
                    href="https://drive.google.com/u/0/uc?id=1uo7ngmhgj_wfQdh-t1S2EJ7ImVdzTOMw&export=download"
                    // target="_blank"
                    download
                    sx={{ display: { xs: "block", lg: "none" } }}
                  >
                    <img src={downloadIcon} className="margin-right-10" />
                    Download
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="content__wrapper">
            <Grid container>
              <Grid item xs={12} lg={6} sx={{ pt: { lg: "64px" } }}>
                <div className="color-description" data-aos="fade-right">
                  <h1>Colors</h1>
                  <p>
                    These are all of the core brand colors we use for Insured
                    Finance
                    <br /> projects. They are labeled and displayed with hex
                    color values.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ pl: { lg: "64px" }, pt: { xs: "24px", lg: "64px" } }}
              >
                <div className="color-picture" data-aos="fade-left">
                  <div className="color-container">
                    <Grid container maxWidth="100%">
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#51CDD3" }}
                        />
                        <span className="color-text">Primary - 300</span>
                        <span className="color-code">#51CDD3</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#B1F2F5" }}
                        />
                        <span className="color-text">Primary - 200</span>
                        <span className="color-code">#B1F2F5</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#EAF8F9" }}
                        />
                        <span className="color-text">Primary - 100</span>
                        <span className="color-code">#EAF8F9</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#3ACCE0" }}
                        />
                        <span className="color-text">Accent</span>
                        <span className="color-code">#3ACCE0</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="color-container">
                    <Grid container>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#000" }}
                        />
                        <span className="color-text">Text</span>
                        <span className="color-code">#000</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#ffffff" }}
                        />
                        <span className="color-text">Text - Light</span>
                        <span className="color-code">#ffffff</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="color-container">
                    <Grid container>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#E1E5EB" }}
                        />
                        <span className="color-text">Neutral - 200</span>
                        <span className="color-code">#E1E5EB</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#AFBFD3" }}
                        />
                        <span className="color-text">Neutral - 300</span>
                        <span className="color-code">#AFBFD3</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#788FB1" }}
                        />
                        <span className="color-text">Neutral - 400</span>
                        <span className="color-code">#788FB1</span>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#4C638E" }}
                        />
                        <span className="color-text">Neutral - 500</span>
                        <span className="color-code">#4C638E</span>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="content__wrapper">
            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="typography-description" data-aos="fade-right">
                  <h1>Typography</h1>
                  <p>
                    Insured Finance uses Open Sans font family for online and
                    printed communications. Design by Steve Matteson
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="typography-picture" data-aos="fade-left">
                  <div className="font-wrapper">
                    <p className="font-700">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                    <p className="font-i-700">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                  </div>
                  <div className="font-wrapper">
                    <p className="font-600">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                    <p className="font-i-600">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                  </div>
                  <div className="font-wrapper">
                    <p className="font-normal">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                    <p className="font-i-normal">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                  </div>
                  <div className="font-wrapper">
                    <p className="font-300">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                    <p className="font-i-300">
                      We have lingered long enough on the shores of the cosmic
                      ocean.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default BrandAssets;
