import React from "react";
import "./PageTitle.scss";

const PageTitle = (props) => {
  const { title, description, background } = props;
  return (
    <div
      data-aos="zoom-in"
      className="page-title"
      style={{
        color: "#000",
        background: `url(${background}) center`,
      }}
    >
      <h1>{title}</h1>
      {description}
    </div>
  );
};

export default PageTitle;
